import React from 'react';
import { navigate } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import Layout from '../components/layout';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import GamePreviewCard from '../components/game-preview-card';
import InfoCard from '../components/info-card';
import games from '../games';


class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gameQuery: 'CS:GO',
      source: 'CS:GO',
      target: 'Fortnite',
      sourceSensitivity: 1.2,
      targetSensitivity: 1,
      dpi: 800,
      in360: 1,
      cm360: 1,
      deviceWidth: 0,
      copied: false,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setState({deviceWidth: window.innerWidth});
      // window.postscribe('#amazon-top', buildTopBannerScript());
    }
    if (this.gameQuery) {
      return this.setState({ gameQuery: this.gameQuery });
    }
    return this.calculateSensitivity();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.copied) {
      setTimeout(() => {
        this.setState({ copied: false });
      }, 1500);
    }

    const game = getGameBySlug(this.gameQuery);

    if (game && this.state.source !== game.name) {
      this.setState({ source: game.name }, () => this.calculateSensitivity());
    }
    else if (
      (prevState.target !== this.state.target) ||
      (prevState.sourceSensitivity !== this.state.sourceSensitivity) ||
      (prevState.dpi !== this.state.dpi)
    ) {
      this.calculateSensitivity();
    }
  }

  calculateSensitivity() {
    const sourceGame = getGameByName(this.state.source);
    const targetGame = getGameByName(this.state.target);
    const calculator = new SensitivityCalculator(
      sourceGame,
      targetGame,
      this.state.sourceSensitivity,
      this.state.dpi
    );
    const targetSensitivity = calculator.calculate();
    const cm360 = calculator.cm360();
    const in360 = calculator.inches360();
    this.setState({
      targetSensitivity,
      cm360,
      in360,
    });
  }

  get gameQuery() {
    return this.URLParams.get('game');
  }

  get URLParams() {
    return new URLSearchParams(this.props.location.search);
  }

  handleInputChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.name === 'source') {
      const sourceGame = getGameByName(value);
      if (sourceGame) {
        const p = new URLSearchParams();
        p.set('game', sourceGame.slug);
        return navigate(`?${p.toString()}`);
      }
    }
    this.setState({
      [target.name]: value
    });
  }

  render() {
    const sourceGame = getGameByName(this.state.source);
    // const targetGame = getGameByName(this.state.target);

    return (
      <Layout
        title='Game Sensitivity Calculator'
        description=' Mouse Sensitivity Converter | Calculator. Free tool to convert mouse sensitivity between games.'
        keywords={[
          'sensitivity converter',
          'sensitivity calculator',
          'game sensitivity converter',
          'game sensitivity calculator',
          'how to use mouse sensitivity calculator'
        ]}
      >
        <Container className='mt-5' style={{maxWidth: 940}}>
          <div className='d-flex justify-content-center mb-5'>
            <a href='https://chrome.google.com/webstore/detail/tabmanagerio-tab-session/hjicnemefbakbanoigbaonlpgflhggdf'>
              <Image src='/images/tabmanager-banner.png' width='728' height='90' />
            </a>
          </div>
          <section id='welcome'>
            <div className='text-center'>
              <h1>Mouse Sensitivity Converter</h1>
            </div>
            <Form className='mt-5 p-2'>
              <Form.Row>
                <Form.Group as={Col} sm controlId='formGridSource'>
                  <Form.Label>From game</Form.Label>
                  <Form.Control
                    name='source'
                    as='select'
                    size='lg'
                    onChange={this.handleInputChange}
                    value={this.state.source}
                  >
                    {games.map(game => <option key={game.slug}>{game.name}</option>)}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} sm controlId='formGridSourceSensitivity'>
                  <Form.Label>Sensitivity</Form.Label>
                  <Form.Control
                    name='sourceSensitivity'
                    type='number'
                    size='lg'
                    placeholder='1'
                    onChange={this.handleInputChange}
                    value={this.state.sourceSensitivity}
                    />
                </Form.Group>

                <Form.Group as={Col} sm controlId='formGridDPI'>
                  <Form.Label>Mouse DPI</Form.Label>
                  <Form.Control
                    name='dpi'
                    type='number'
                    size='lg'
                    placeholder='800'
                    onChange={this.handleInputChange}
                    value={this.state.dpi}
                    />
                </Form.Group>

              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} sm controlId='formGridTarget'>
                  <Form.Label>To game</Form.Label>
                  <Form.Control
                    name='target'
                    as='select'
                    size='lg'
                    onChange={this.handleInputChange}
                    value={this.state.target}
                  >
                    {games.map(game => <option key={game.slug}>{game.name}</option>)}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId='formGridTargetSensitivity'>
                  <Form.Label>Sensitivity</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type='number'
                      size='lg'
                      placeholder='1'
                      readOnly
                      value={this.state.targetSensitivity}
                    />
                    <InputGroup.Append>
                      <CopyToClipboard text={this.state.targetSensitivity}
                        onCopy={() => this.setState({ copied: true })}>
                        <Button size='sm' variant='secondary' disabled={this.state.copied}>Copy</Button>
                      </CopyToClipboard>
                    </InputGroup.Append>
                  </InputGroup>
                  <Form.Text className="text-dark" hidden={!this.state.copied}>
                    Copied to clipboard
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm='2' controlId='formGridTargetIN360'>
                  <Form.Label>In per 360°</Form.Label>
                  <Form.Control
                    type='number'
                    size='lg'
                    placeholder='1'
                    readOnly
                    value={this.state.in360}
                  />
                </Form.Group>

                <Form.Group as={Col} sm='2' controlId='formGridTargetCM360'>
                  <Form.Label>Cm per 360°</Form.Label>
                  <Form.Control
                    type='number'
                    size='lg'
                    placeholder='1'
                    readOnly
                    value={this.state.cm360}
                  />
                </Form.Group>

              </Form.Row>

            </Form>
          </section>
          <section className='mt-5'>
            <div id='amazon-top' className='flex-container'></div>
          </section>
          <section className='mt-5'>
            <InfoCard />
          </section>
          <section className='mt-5'>
            <GamePreviewCard
              title={this.state.source}
              url={sourceGame ? sourceGame.url : ''}
              description={sourceGame ? sourceGame.description : ''}
              logo={sourceGame ? sourceGame.logo : ''}
            />
          </section>
        </Container>
      </Layout>
    );
  }
}

class SensitivityCalculator {
  constructor(source, target, gameSens, dpi) {
    this.source = source;
    this.target = target;
    this.gameSens = gameSens || 1;
    this.dpi = dpi || null;
    this.normalisedSens = 1;
    this.in360norm = 6283.1853;
  }
  calculate() {
    let sourceSens = 1;
    let targetSens = 1;
    // Normalize source sensitivity.
    if (this.source.type === 'normal') {
      sourceSens = this.gameSens / this.source.divisor;
    } else if (this.source.type === 'exponential') {
      sourceSens = this.source.a * Math.pow(this.source.b, this.gameSens);
    } else if (this.source.type === 'linear') {
      sourceSens = this.source.a * this.gameSens + this.source.c;
    }

    if (this.target.type === 'normal') {
      targetSens = sourceSens * this.target.divisor;
    } else if (this.target.type === 'exponential') {
      targetSens = Math.log(sourceSens / this.target.a) / Math.log(this.target.b);
    } else if (this.target.type === 'linear') {
      targetSens = (sourceSens - this.target.c) / this.target.a;
    }

    this.normalisedSens = sourceSens;
    return targetSens.toFixed(4);
  }
  inches360() {
    return (this.in360norm / this.normalisedSens / this.dpi).toFixed(2);
  }
  cm360() {
    return (this.in360norm / this.normalisedSens / this.dpi * 2.54).toFixed(2);
  }
}

function getGameByName(name) {
  return games.find(game => game.name === name);
}

function getGameBySlug(slug) {
  return games.find(game => game.slug === slug);
}

// function buildTopBannerScript() {
//   return `
//     <script type="text/javascript">
//       amzn_assoc_ad_type = "banner";
//       amzn_assoc_marketplace = "amazon";
//       amzn_assoc_region = "US";
//       amzn_assoc_placement = "assoc_banner_placement_default";
//       amzn_assoc_campaigns = "computers_accesories";
//       amzn_assoc_banner_type = "category";
//       amzn_assoc_p = "48";
//       amzn_assoc_isresponsive = "false";
//       amzn_assoc_banner_id = "14G4K4WP5C3DQPMGJY02";
//       amzn_assoc_width = "728";
//       amzn_assoc_height = "90";
//       amzn_assoc_tracking_id = "octarine0f-20";
//       amzn_assoc_linkid = "935fe2cc9610f2942ff43cd8b8ee4499";
//     </script>
//     <script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>
//   `;
// }

export default Main;