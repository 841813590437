import React from 'react';
import injectSheet from 'react-jss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';


const styles = {
  root: {
    width: '100%',
    maxWidth: 900,
    '& .card-title': {
      fontSize: '1.85rem',
    },
    '& img': {
      padding: 20,
      border: '1px solid rgba(0,0,0,.125)'
    },
  }
};

class GamePreviewCard extends React.Component {

  render() {
    const { classes, title, url, logo, description } = this.props;
    return (
      <Card className={classes.root}>
        <Row noGutters='true'>
          <Col md='4' className={`p-4 text-center`}>
            <Image src={logo} alt={`${title} sensitivity converter`}/>
            <Button size='lg' block variant='dark' href={url}>Visit Website</Button>
          </Col>
          <Col md='8'>
            <Card.Body className={`ml-0 ml-md-3 pt-0 pt-md-4`}>
              <Row noGutters='true'>
                <Col>
                  <Card.Title className='mt-1'>{title}</Card.Title>
                </Col>
              </Row>
              <Card.Text className={`mt-3`}>{description}</Card.Text>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    );
  }

}

const StyledGamePreviewCard = injectSheet(styles)(GamePreviewCard);

GamePreviewCard.defaultProps = {
  title: '',
  url: '',
  description: '',
  logo: ''
};

export default StyledGamePreviewCard;