import React from 'react';
import injectSheet from 'react-jss';
import Card from 'react-bootstrap/Card';


const styles = {
  root: {
    width: '100%',
    maxWidth: 900,
    '& .card-title': {
      fontSize: '1.85rem',
    },
  }
};

class InfoCard extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <Card.Body>
          <Card.Title>Information</Card.Title>
          <Card.Text>
            This calculator converts your gaming mouse sensitivity between games, allowing you to maintain the same
            level of consistency in your aim when playing multiple games.<br /><br />
          </Card.Text>
          <h4>How to Use Calculator</h4>
          <Card.Text>
            Select your game and input the sensitivity value found in your game config, then select the
            game you want to convert the value to, the result will be calculated and displayed immediately in the corresponding field below the input.<br /><br />
            For a more accurate sensitivity result input your <a href='https://en.wikipedia.org/wiki/Computer_mouse#Mouse_speed'>Mouse DPI </a>
            (normally displayed in your mouse software settings). This will also provide you with information how many centimeters,
            or inches, of mouse movement it takes to complete a 360° turn in game.
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }

}

const StyledInfoCard = injectSheet(styles)(InfoCard);

export default StyledInfoCard;